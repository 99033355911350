'use client';

import { useMemo } from 'react';

import { useTranslation } from '@cms/i18n';
import { CuratedMatch, GrandPrix, HeadlinesDossier, News, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import {
    DividerTimespan,
    getDividerTimespan,
    NewsList,
    Props as NewsListProps,
} from '@web/molecules/NewsList';
import { useNewsListThemeConfig } from '@web/molecules/NewsList/NewsList.config';
import { Props as OddsDossierIntroProps } from '@web/molecules/OddsDossierIntro/OddsDossierIntro';

import { useInjections } from './useInjections';
import { isToday } from './utils';

export interface Props extends Partial<NewsListProps> {
    platform: PlatformID;
    news: News[];
    onReadMoreClick?: () => void;
    hasNextPage?: boolean;
    headlinesDossier?: HeadlinesDossier;
    oddsDossier?: OddsDossierIntroProps;
    upcomingGP?: GrandPrix;
    videonews?: News[];
    curatedMatches?: CuratedMatch[];
}

const useDatePrefix = (items: News[]): string => {
    const contextData = useContextData();
    const locale = contextData.context.locale;
    const __datetime = useTranslation('datetime').t;
    const date = items[0]?.newsDate ?? items[0]?.newsPublishDate;

    if (!date) {
        return '';
    }

    const timespan = getDividerTimespan(items);

    if (timespan === DividerTimespan.MONTH) {
        return DateTimeUtil.format(date, Format.MONTH_FULL, locale);
    }

    if (isToday(date)) {
        return __datetime('today');
    }

    return DateTimeUtil.format(date, Format.DATE_MEDIUM_WITHOUT_YEAR, locale);
};

export const HomeNewsList = ({ platform, onReadMoreClick, hasNextPage, ...props }: Props) => {
    const { news, curatedMatches } = props;
    const { displayType } = useNewsListThemeConfig();
    const injections = useInjections(platform, props, displayType);
    const __meta = useTranslation('meta').t;

    const featuredItems = useMemo(() => news.filter((item) => item.featured), [news]);
    const newsItems = useMemo(() => news.filter((item) => !item.featured), [news]);

    const datePrefix = useDatePrefix(newsItems);
    const metaPlatformTitle = __meta(`${platform}-homepage.news-list-title`);
    const title = `${datePrefix} - ${metaPlatformTitle}`;

    return (
        <NewsList
            {...props}
            displayType={displayType}
            title={title}
            items={newsItems}
            curatedMatches={curatedMatches}
            featuredItems={featuredItems}
            pagination={{ hasNextPage }}
            injections={injections}
            onReadMoreClick={onReadMoreClick}
        />
    );
};
